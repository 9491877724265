import React from 'react';

import HorizontalNavigation from '../../main/horizontal_navigation/horizontal_navigation_container';
import ContentContainer from './content_container';
import RequestsContentContainer from '../../my_settings/organization_requests/content_container';

import withRouter from '../../../util/with_router';

class MyAssignments extends React.Component {

  componentDidMount() {}

  render() {
    const { currentUser, currentLocale } = this.props;

    return (
      <div className='main contactimmed-main'>
        <HorizontalNavigation
          currentUser={currentUser}
          currentLocale={currentLocale}
        />
        <RequestsContentContainer
          currentUser={currentUser}
          currentLocale={currentLocale}
          isMyAssignmentsList={true}
        />
      </div>
    );
  }
}

export default withRouter(MyAssignments);
