import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import Modal from 'react-modal';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import NoResultImage from 'images/no_result.png';

import ChannelListContainer from './channel_list/channel_list_container';
import ChannelContainer from './channel/channel_container';
import HorizontalNavigation from './horizontal_navigation/horizontal_navigation_container';

import MessageFormContainer from './channel/message_form/message_form_container';
import ChannelHeaderContainer from './channel/channel_header/channel_header_container';
import MessageListContainer from './channel/message_list/message_list_container';
import ChannelMetadataContainer from './channel/channel_metadata/channel_metadata_container';
import FilteredListContainer from './channel/filtered_list/filtered_list_container';
import GeneralUpgradePlanModal from '../my_settings/pricing_plans/general_upgrade_plan_modal';

import withRouter from '../../util/with_router';

import { channelsFiltersQs, channelsTagsFiltersQs } from '../util/helpers';
import { getCountForChannels } from '../../services/channel_api';

class Main extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      isChannelsLoading: true,
      isLeftPanelCollapsed: false,
      isRightPanelCollapsed: false,
      ignoreLeftPanelReFetch: false,
    }
    this.handleGeneralUpgradePlanModal = this.handleGeneralUpgradePlanModal.bind(this);
  }

  async componentDidMount() {
    this._isMounted = true;
    Modal.setAppElement(this.el);
    const channelsFilter = channelsFiltersQs(this.props.location.search);
    const channelsTagsFilter = channelsTagsFiltersQs(this.props.location.search);
    const channelsFetchParams = {
      ...(channelsFilter && { channelsFilter }),
      ...(channelsTagsFilter && { channelsTagsFilter }),
    }
    await this.props.fetchChannels(channelsFetchParams);
    // NOTE: View explanation in `main_container` for why this is called here. It's
    // a stopgap until `onSearchChange` can be resolved with react-draft-wysiwyg
    await this.props.fetchUserMentions({});

    if (this._isMounted) {
      this.setState({
        isChannelsLoading: false,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  // Toggle left panel collapse
  toggleLeftPanel = () => {
    this.setState(prevState => ({
      isLeftPanelCollapsed: !prevState.isLeftPanelCollapsed,
      ignoreLeftPanelReFetchOnComponentDidMount: true,
    }));
  };

  // Toggle right panel collapse
  toggleRightPanel = () => {
    this.setState(prevState => ({
      isRightPanelCollapsed: !prevState.isRightPanelCollapsed
    }));
  };

  handleGeneralUpgradePlanModal = async () => {
    const {
      currentLocale,
      currentUser,
      isFreeTrialAccount,
      openModal,
      workspaceId,
    } = this.props;

    if (!isFreeTrialAccount) {
      return openModal('new_public_channel', { currentLocale, currentUser, workspaceId });
    }

    const organizationId = currentUser?.organization_id;
    const response = await getCountForChannels({
      organizationId,
      workspaceId,
    });

    const count = response?.total_count;

    if (isFreeTrialAccount && count > 100) {
      return confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <GeneralUpgradePlanModal
              onClose={onClose}
              navigate={this.props.navigate}
            />
          );
        }
      });
    }

    return openModal('new_public_channel', { currentLocale, currentUser, workspaceId });
  }

  render() {
    const {
      areNoActiveRequests,
      channelId,
      currentUser,
      location,
      workspaceId,
      currentLocale,
      actionCabelUrl,
      isFreeTrialAccount,
      workspaces,
    } = this.props;

    const {
      isChannelsLoading,
      ignoreLeftPanelReFetchOnComponentDidMount,
      isLeftPanelCollapsed,
      isRightPanelCollapsed,
    } = this.state;

    const workspace = workspaces.filter(workspace => workspace.id === workspaceId)[0];

    const isFilteredChannelsView =
      location?.pathname === `/workspaces/${workspaceId}/requests/filter`;

    // Determine the width of the middle section based on the panel states
    const middleSectionClass = `col-12 ${
      !isLeftPanelCollapsed && !isRightPanelCollapsed
        ? 'col-lg-6'
        : isLeftPanelCollapsed && !isRightPanelCollapsed
        ? 'col-lg-9'
        : !isLeftPanelCollapsed && isRightPanelCollapsed
        ? 'col-lg-9'
        : 'col-lg-12'
    } d-flex flex-column p-3 100-vh`;

    if (!isChannelsLoading && !workspace) {
      return (
        <div className="main contactimmed-main vh-100 no-workspaces-found" ref={ref => this.el = ref}>
          <HorizontalNavigation currentUser={currentUser} currentLocale={currentLocale} />
          <Container fluid className="no-result vh-100 py-5 d-flex justify-content-center mt-4">
            <div className="workspace-not-found d-flex flex-column justify-content-center align-items-center">
              <div className="image-container mb-4">
                <img src={NoResultImage} width={100} />
              </div>
              <div className="header">Workspace Not Found</div>
              <div className="subheader text-center pt-3 mb-3">
                This workspace doesn't exist, or you haven't been granted access
                by your administrator.
              </div>
              <div className="subheader text-center pt-3 mb-3">
                If this is an access issue, please contact your adminstrator
                for support. In the mean time, you can view the workspace(s)
                that you currently have access to from the navigation menu.
              </div>
              <a
                href={`/#/${currentLocale}`}
                target="_blank"
                className="view-demo-dashboard-button mt-4 px-4 py-3"
              >
                Go To Dashboard
              </a>
            </div>
          </Container>
        </div>
      );
    }

    return (
      <div className="main contactimmed-main" ref={ref => this.el = ref}>
        <HorizontalNavigation currentUser={currentUser} currentLocale={currentLocale} />
        <div className="container-fluid">
          <div className="row flex-grow-1">
            <div className={`col-12 col-lg-3 p-3 border ${isLeftPanelCollapsed ? 'collapse' : ''}`}>
              {!isLeftPanelCollapsed && (
                <div className="h-100">
                  <ChannelListContainer
                    key={`channel-list-${workspaceId}`}
                    currentUser={currentUser}
                    currentLocale={currentLocale}
                    toggleLeftPanel={this.toggleLeftPanel}
                    ignoreLeftPanelReFetchOnComponentDidMount={ignoreLeftPanelReFetchOnComponentDidMount}
                  />
                </div>
              )}
            </div>
            {isChannelsLoading && (
              <div className="col-12 col-lg-9 d-flex flex-column p-3">
                <div className="loading-spinner-container d-flex align-items-center justify-content-center bg-white vh-100">
                  <Spinner animation="border" role="status" variant="secondary" />
                </div>
              </div>
            )}
            {!isChannelsLoading && isFilteredChannelsView && (
              <div className="col-12 col-lg-9 d-flex flex-column p-3">
                <FilteredListContainer
                  currentUser={currentUser}
                  currentLocale={currentLocale}
                  key={`channels-list-filter-${location?.search}`}
                />
              </div>
            )}
            {!isChannelsLoading && !isFilteredChannelsView && areNoActiveRequests && (
              <div className="col-12 col-lg-9 d-flex flex-column p-3 no-active-requests">
                <div className="no-result summary-list-view filtered-channels-list-view mb-5">
                  <div className="request-not-found d-flex pt-2 mt-5 flex-column justify-content-center align-items-center">
                    <div className="image-container mb-4">
                      <img src={NoResultImage} width={100} />
                    </div>
                    <div className="header">No Active Requests</div>
                    <div className="subheader text-center pt-3 mb-3">
                      This workspace does not currently have active requests.
                    </div>
                    <div className="subheader text-center pt-3 mb-3">
                      To create a request, you can click the button below or
                      the "plus" button to your left.
                    </div>
                    <div
                      onClick={() => isFreeTrialAccount
                        ? this.handleGeneralUpgradePlanModal()
                        : this.props.openModal('new_public_channel', { currentLocale, currentUser, workspaceId })
                      }
                      className="view-demo-dashboard-button mt-4 px-4 py-3"
                    >
                      New Request
                    </div>
                  </div>
                </div>
              </div>
            )}

            {!isChannelsLoading && !isFilteredChannelsView && !areNoActiveRequests && (
              <>
                <div className={middleSectionClass}>

                  <div className={`d-flex ${isLeftPanelCollapsed ? 'justify-content-between' : 'justify-content-end'}`}>
                    {isLeftPanelCollapsed && (
                      <div
                        className="d-flex mb-1 collapse-icon-container"
                        onClick={this.toggleLeftPanel}
                        style={{ fontSize: 12 }}
                      >
                        <i
                          className={`bi collapse-icon me-1 ${isLeftPanelCollapsed ? 'bi-arrows-expand-vertical' : 'bi-arrows-collapse-vertical'}`}
                        ></i>
                        {isLeftPanelCollapsed ? 'Expand' : 'Collapse'}
                      </div>
                    )}
                    {isRightPanelCollapsed && (
                      <div
                        className="d-flex mb-1"
                        onClick={this.toggleRightPanel}
                        style={{ fontSize: 12 }}
                      >
                        <i
                          className={`bi collapse-icon me-1 ${isRightPanelCollapsed ? 'bi-arrows-expand-vertical' : 'bi-arrows-collapse-vertical'}`}
                        ></i>
                        {isRightPanelCollapsed ? 'Expand' : 'Collapse'}
                      </div>
                    )}
                  </div>
                  <div className="mb-3 channel-header-container">
                    <div className="bg-white p-3 rounded">
                      <ChannelHeaderContainer
                        currentUser={currentUser}
                        key={`channel-header-${channelId}`}
                      />
                    </div>
                  </div>
                  <div className="flex-grow-1 overflow-auto message-list-container">
                    <MessageListContainer
                      actionCabelUrl={actionCabelUrl}
                      key={`message-list-${channelId}`}
                    />
                  </div>

                  <div className="bottom-message-input-bar">
                    <MessageFormContainer
                      currentUser={currentUser}
                      actionCabelUrl={actionCabelUrl}
                    />
                    {/*
                      <form>
                        <div className="input-group">
                          <input type="text" className="form-control" placeholder="Type a message" />
                          <button className="btn btn-primary" type="submit">Send</button>
                        </div>
                      </form>
                    */}
                  </div>
                </div>

                <div className={`col-12 col-lg-3 p-3 border ${isRightPanelCollapsed ? 'collapse' : ''}`}>
                  {!isRightPanelCollapsed && (
                    <div className="h-100">
                      <ChannelMetadataContainer
                        currentUser={currentUser}
                        toggleRightPanel={this.toggleRightPanel}
                      />
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Main);
