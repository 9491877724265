import { connect } from 'react-redux';
import Content from './content';
import withRouter from '../../../util/with_router';
import { fetchOrganizationRequests } from '../../../actions/organization_actions';
import { fetchCurrentUserAssignments } from '../../../actions/user_actions';
import {
  isEducationOrganizationPersona,
  organizationPersona
} from '../../../reducers/selectors';

const msp = (state, ownProps) => {
  const { entities } = state;

  return {
    currentUser: entities.users[state.session.id],
    requests: entities.organization.requests,
    pages: entities.organization.pages || 1,
    requestsCount: entities.organization.requestsCount || 0,
    organizationPersona: organizationPersona({ entities }),
    workspaces: entities.workspaces,
    location: ownProps.location,
    isEducationOrganizationPersona: isEducationOrganizationPersona({ entities }),
    assignments: entities.currentUser.assignments.entries || [],
  };
};

const mdp = (dispatch, ownProps) => {
  const { currentUser } = ownProps;
  const organizationId = currentUser?.organization_id;
  const { id } = currentUser;

  return {
    fetchRequests: ({ filters, page, pageSize, search, sort }) =>
      dispatch(fetchOrganizationRequests({ organizationId, filters, page, pageSize, search, sort })
    ),
    fetchUserAssignments: ({ page, pageSize, search }) =>
      dispatch(fetchCurrentUserAssignments({
        organizationId,
        id,
        page,
        pageSize
      })
    ),
  };
};

export default withRouter(connect(msp, mdp)(Content));
