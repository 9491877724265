import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import selectTableHOC from 'react-table/lib/hoc/selectTable';
import 'react-table/react-table.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import ConfirmDeleteMessage from './ConfirmDeleteMessage';
import ConfirmSubmitMessage from './ConfirmSubmitMessage';

const SelectTable = selectTableHOC(ReactTable);

const isFunction = method => typeof method === 'function';

class TableComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectAll: false,
      selection: [],
    };
    this.handleConfirmDelete = this.handleConfirmDelete.bind(this);
    this.handleConfirmDisable = this.handleConfirmDisable.bind(this);
    this.handleConfirmDeny = this.handleConfirmDeny.bind(this);
    this.handleConfirmApprove = this.handleConfirmApprove.bind(this);
  }

  handleConfirmDelete = async (event) => {
    event && event.preventDefault();
    const { selection } = this.state;
    const {
      handleOnClickRemoveMultipleItems,
      deleteConfirmationSubtitle,
    } = this.props;

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmDeleteMessage
            handleRemoveItem={handleOnClickRemoveMultipleItems}
            subtitle={deleteConfirmationSubtitle}
            itemsToRemove={selection}
            isMultipleDeletion={true}
            event={event}
            onClose={onClose}
          />
        );
      }
    });
  }

  handleConfirmDisable = async (event) => {
    event && event.preventDefault();
    const { selection } = this.state;
    const {
      handleOnClickDisableMultipleUsers,
      disableConfirmationSubtitle,
    } = this.props;

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmDeleteMessage
            handleRemoveItem={handleOnClickDisableMultipleUsers}
            subtitle={disableConfirmationSubtitle}
            itemsToRemove={selection}
            isMultipleDeletion={true}
            event={event}
            onClose={onClose}
          />
        );
      }
    });
  }

  handleConfirmDeny = async (event) => {
    event && event.preventDefault();
    const { selection } = this.state;
    const {
      handleOnClickDenyMultipleUsers,
      denyConfirmationSubtitle,
    } = this.props;

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmDeleteMessage
            handleRemoveItem={handleOnClickDenyMultipleUsers}
            subtitle={denyConfirmationSubtitle}
            itemsToRemove={selection}
            isMultipleDeletion={true}
            event={event}
            onClose={onClose}
          />
        );
      }
    });
  }

  handleConfirmApprove = async (event) => {
    event && event.preventDefault();
    const { selection } = this.state;
    const {
      handleOnClickApproveMultipleUsers,
      approveConfirmationSubtitle,
    } = this.props;

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmSubmitMessage
            handleSubmitItem={handleOnClickApproveMultipleUsers}
            subtitle={approveConfirmationSubtitle}
            itemsToSubmit={selection}
            isMultipleSubmission={true}
            event={event}
            onClose={onClose}
          />
        );
      }
    });
  }

  /**
   * Toggle a single checkbox for select table
   */
  toggleSelection = (key, shift, row) => {
    const { handleSelection } = this.props;
    // start off with the existing state
    let selection = [...this.state.selection];
    // const keyIndex = selection.indexOf(key);
    const keyIndex = selection.indexOf(key?.replace('select-', ''));

    // check to see if the key exists
    if (keyIndex >= 0) {
      // it does exist so we will remove it using destructing
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1)
      ];
    } else {
      // it does not exist so add it
      selection.push(key?.replace('select-', ''));
      // selection.push(key);
    }
    // update the state
    this.setState({ selection });
    handleSelection({ selection });
  };

  /**
   * Toggle all checkboxes for select table
   */
  toggleAll = () => {
    const { handleSelection, keyField } = this.props;
    const selectAll = !this.state.selectAll;
    const selection = [];

    if (selectAll) {
      // we need to get at the internals of ReactTable
      const wrappedInstance = this.checkboxTable.getWrappedInstance();
      // the 'sortedData' property contains the currently accessible records based on the filter and sort
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      // we just push all the IDs onto the selection array
      currentRecords.forEach(item => {
        selection.push(item._original[keyField]);
        // selection.push(`select-${item._original[keyField]}`);
      });
    }
    this.setState({ selectAll, selection });
    handleSelection({ selection });
  };

  /**
   * Whether or not a row is selected for select table
   */
  isSelected = key => {
    return this.state.selection.includes(key);
    // return this.state.selection.includes(`select-${key}`);
  };

  rowFn = (state, rowInfo, column, instance) => {
    const { selection } = this.state;

    return {
      onClick: (e, handleOriginal) => {
        // console.log("It was in this row:", rowInfo);

        // IMPORTANT! React-Table uses onClick internally to trigger
        // events like expanding SubComponents and pivots.
        // By default a custom 'onClick' handler will override this functionality.
        // If you want to fire the original onClick handler, call the
        // 'handleOriginal' function.
        if (handleOriginal) {
          handleOriginal();
        }
      },
      // style: {
      //   background:
      //     rowInfo &&
      //     selection.includes(`select-${rowInfo.original.id}`) &&
      //     "lightgreen"
      // }
    };
  };

  render() {
    const {
      columns,
      data,
      defaultPageSize,
      handleOnFetchData,
      handleGetTableProps,
      handleGetTbodyProps,
      handleGetTdProps,
      keyField,
      loading,
      multipleDeletionEnabled,
      multipleDisableEnabled,
      multipleStatusUpdateEnabled,
      multipleApprovalEnabled,
      multipleDenialEnabled,
      noDataText,
      pages,
      rowSelectionEnabled,
    } = this.props;

    const { selection } = this.state;

    const areRowsSelected = selection?.length > 0;

    const tableActionClass = areRowsSelected
      ? 'ms-4 table-action enabled'
      : 'ms-4 table-action disabled';

    return (
      <div className="select-table-container">
        <div className="d-flex align-items-center justify-content-end table-actions">
          {multipleApprovalEnabled && (
            <div
              className={tableActionClass}
              onClick={(event) => areRowsSelected ? this.handleConfirmApprove(event) : null}
            >
              Approve all
            </div>
          )}
          {multipleDenialEnabled && (
            <div
              className={tableActionClass}
              onClick={(event) => areRowsSelected ? this.handleConfirmDeny(event) : null}
            >
              Deny all
            </div>
          )}
          {multipleStatusUpdateEnabled && (
            <div
              className={tableActionClass}
              onClick={(event) => areRowsSelected ? console.log('!!! implement me --->') : null}
            >
              update status
            </div>
          )}
          {false && multipleDisableEnabled && (
            <div
              className={tableActionClass}
              onClick={(event) => areRowsSelected ? this.handleConfirmDisable(event) : null}
            >
              disable
            </div>
          )}
          {multipleDeletionEnabled && (
            <div
              className={tableActionClass}
              onClick={(event) => areRowsSelected ? this.handleConfirmDelete(event) : null}
            >
              delete
            </div>
          )}
        </div>
        {rowSelectionEnabled && (
          <SelectTable
            ref={r => (this.checkboxTable = r)}
            data={data}
            defaultPageSize={defaultPageSize}
            loading={loading}
            pages={pages}
            columns={columns}
            noDataText={noDataText}
            manual
            onFetchData={(state, instance) => {
              handleOnFetchData({ state });
            }}
            toggleSelection={this.toggleSelection}
            selectAll={this.state.selectAll}
            selectType={'checkbox'}
            toggleAll={this.toggleAll}
            isSelected={this.isSelected}
            getTrProps={this.rowFn}
            keyField={keyField}
            getTableProps={() => isFunction(this.handleGetTableProps)
              ? handleGetTableProps()
              : {}
            }
            getTbodyProps={() => isFunction(this.handleGetTbodyProps)
              ? handleGetTbodyProps()
              : {}
            }
            getTdProps={(state, rowInfo, column) => {
              if (isFunction(this.handleGetTdProps)) {
                return handleGetTdProps({ column });
              }
              return {};
            }}
          />
        )}
        {!rowSelectionEnabled && (
          <ReactTable
            data={data}
            defaultPageSize={defaultPageSize}
            loading={loading}
            pages={pages}
            columns={columns}
            noDataText={noDataText}
            manual
            onFetchData={(state, instance) => {
              handleOnFetchData({ state });
            }}
          />
        )}

      </div>
    );
  }
}

TableComponent.propTypes = {};

TableComponent.defaultProps = {
 keyField: 'id',
 rowSelectionEnabled: true,
 noDataText: 'No results found',
};

export default TableComponent;
