import { connect } from 'react-redux';
import Content from './content';
import withRouter from '../../../util/with_router';
import { fetchCurrentUserAssignments } from '../../../actions/user_actions';
import { organizationPersona } from '../../../reducers/selectors';

const msp = (state) => {
  const { entities } = state;
  return {
    currentUser: entities.currentUser,
    assignments: entities.currentUser.assignments.entries || [],
    pages: entities.currentUser.invitations.pages || 1,
    assignmentsCount: entities.currentUser.assignments.entries.count || 0,
    organizationPersona: organizationPersona({ entities }),
    workspaces: entities.workspaces,
  };
};

const mdp = (dispatch, ownProps) => {
  const { currentUser } = ownProps;
  const { id } = currentUser;
  const organizationId = currentUser?.organization_id;

  return {
    fetchAssignments: ({ page, pageSize, search }) =>
      dispatch(fetchCurrentUserAssignments({
        organizationId,
        id,
        page,
        pageSize
      })
    ),
  };
};

export default withRouter(connect(msp, mdp)(Content));
