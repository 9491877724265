import { connect } from 'react-redux';
import Main from './main';
import withRouter from '../../util/with_router';

import { openModal } from '../../actions/modal_actions';
import { fetchOrganizationUserMentions } from '../../actions/organization_actions';
import { fetchChannels } from '../../actions/channel_actions';
import {
  isFreeTrialAccount,
} from '../../reducers/selectors';

const NO_ACTIVE_REQUESTS = 'no_active_requests';

const msp = (state, ownProps) => {

  const { entities } = state;
  return {
    currentUser: entities.users[state.session.id],
    isFreeTrialAccount: isFreeTrialAccount({ entities }),
    workspaceId: ownProps.match.params.workspaceId,
    workspaces: entities.workspaces,
    channelId: ownProps.match.params.channelId,
    areNoActiveRequests: ownProps.match.params.channelId === NO_ACTIVE_REQUESTS,
    location: ownProps.location,
  };
};

const mdp = (dispatch, ownProps) => {
  const { currentUser } = ownProps;
  const organizationId = currentUser?.organization_id;
  const workspaceId = ownProps.match.params.workspaceId;

  return {
    fetchChannels: ({ channelsFilter, channelsTagsFilter }) =>
      dispatch(fetchChannels({ organizationId, workspaceId, channelsFilter, channelsTagsFilter })),
    // NOTE: only calling mentions here due to onSearchChange limitations with
    // react-draft-wysiwyg. Ideally, mentions would be dynamic as a user searches
    // for someone to mention. This is already setup in `form_fields/form_fields.jsx`
    // but I temporarily turned if off (commented out the execution) because
    // `message_form/message_form.jsx` also uses mentions and the problem is
    // there with react-draft-wysiwyg. Until the issue is resolved, with
    // either onChange handler update in the library and refactoring to use
    // another library (recommended no matter the case). For the time being,
    // I'm also setting page size, for mentions, to 1000 on the backend. This
    // will provide breathing room until the matter is resolved.
    openModal: (modal, props) => dispatch(openModal(modal, props)),
    fetchUserMentions: ({}) =>
      dispatch(fetchOrganizationUserMentions({ organizationId, workspaceId })),
  };
};

export default withRouter(connect(msp, mdp)(Main));
