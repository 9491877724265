import { paramsToQueryString } from '../services/util/helpers';

export const fetchUsers = ({ organizationId }) => {
  return $.ajax({
    url: `/api/organizations/${organizationId}/users`,
  });
};

export const getCurrentUser = ({ organizationId }) => {
  return $.ajax({
    url: `/api/organizations/${organizationId}/current_users.json`,
  });
};

export const getCurrentUserInvitations = ({ organizationId, id, page, pageSize }) => {
  const params = { page, page_size: pageSize };
  const url = !paramsToQueryString(params)
    ? `/api/organizations/${organizationId}/users/${id}/invitations.json`
    : `/api/organizations/${organizationId}/users/${id}/invitations.json?${paramsToQueryString(params)}`;

  return $.ajax({
    url,
  });
}

export const getCurrentUserAssignments = ({ organizationId, id, page, pageSize }) => {
  const params = { page, page_size: pageSize };
  const url = !paramsToQueryString(params)
    ? `/api/organizations/${organizationId}/users/${id}/assignments.json`
    : `/api/organizations/${organizationId}/users/${id}/assignments.json?${paramsToQueryString(params)}`;

  return $.ajax({
    url,
  });
}

export const updateCurrentUserInvitation = ({ organizationId, userId, id, accepted }) => {
  return $.ajax({
    url: `/api/organizations/${organizationId}/users/${userId}/invitations/${id}`,
    beforeSend: function (xhr) {
      xhr.setRequestHeader('X-CSRF-Token', Rails.csrfToken());
    },
    method: 'PUT',
    contentType: 'application/json',
    data: JSON.stringify({ invitation: { accepted } }),
  });
}
