import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import moment from 'moment';
import 'react-table/react-table.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import {
  numToLocaleString,
  parseQueryString,
  removeEmptyFromObject,
  statusBadge,
  statusMapping,
  toastifyConfiguration,
} from '../../../components/util/helpers';
import currencyCodesHash from '../../../components/util/currency_codes_hash.json';

import RequestsContent from '../../my_settings/organization_requests/content_container';

const DEFAULT_PAGE = 0;
const DEFAULT_PAGE_SIZE = 100;

toast.configure();

class Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pages: 1,
      isPaneOpen: false,
      isPaneOpenLeft: false,
      assignments: [],
      assignmentsCount: 0,
      accepted: undefined,
    };
    this.handleGetPaginatedAssignments = this.handleGetPaginatedAssignments.bind(this);
    this.handleCsvExport = this.handleCsvExport.bind(this);
  }

  componentDidMount() {
    Modal.setAppElement(this.el);
  }

  handleGetPaginatedAssignments = async (page, pageSize) => {
    const { currentUser } = this.props;
    const { search } = this.state;
    await this.props.fetchAssignments({
      page: page + 1,
      pageSize: pageSize,
      search,
    });
    this.setState({ loading: false });
  }


  handleCsvExport = async () => {
    event.preventDefault();
    const { currentUser } = this.props;
    await getUsersCsv({ organization_id: currentUser?.organization_id });
  }

  render() {
    let {
      currentLocale,
      currentUser,
      assignments,
      assignmentsCount,
      pages,
      workspaces,
      organizationPersona,
    } = this.props;

    const hasMoreAThanOneWorkspace = workspaces?.length > 1;
    const isBusinessOrganization = organizationPersona === 'b2b';

    const columns = [{
      id: 'name',
      Header: 'Request name',
      accessor: t => (
        <a
          href={`/#/${currentLocale}/workspaces/${t.workspace_id}/requests/${t.id}`}
          target="_blank"
        >
          {t.name}
        </a>
      ),
    },
    ...hasMoreAThanOneWorkspace ? [{
      id: 'workspace',
      Header: 'Workspace',
      sortable: false,
      accessor: t =>  <span>{getWorkspaceName({ workspaceId: t.workspace_id, workspaces })}</span>,
    }] : [],
    {
      accessor: 'author_email',
      Header: 'Created by',
    }, {
      id: 'status',
      Header: 'Status',
      accessor: t => (
        <div className={`status pill pt-2 pb-2 badge ${statusBadge(t.status)} d-flex align-items-center justify-content-center w-75`}>
          {statusMapping(t.status)}
        </div>
      )
    },
    ...isBusinessOrganization ? [{
      id: 'opportunity_value',
      Header: 'Opportunity value',
      accessor: t => <span>{t.opportunity_value ? `${handleCurrencySymbol(t.opportunity_value_currency)}${numToLocaleString(t.opportunity_value)}` : 'null'}</span>
    }] : [],
    {
      id: 'created_at',
      Header: 'Created at',
      accessor: t => <span>{moment(t.created_at).locale(currentLocale).format('YYYY-MM-DD')}</span>
    }, {
      id: 'updated_at',
      Header: 'Updated at',
      accessor: t => <span>{moment(t.updated_at).locale(currentLocale).format('YYYY-MM-DD')}</span>
    }, {
      id: 'action',
      Header: 'Action',
      sortable: false,
      accessor: t => (
        <div>
          <a
            href={`/#/${currentLocale}/workspaces/${t.workspace_id}/requests/${t.id}`}
            target="_blank"
          >
            <span className='edit-cell'>
              <i className="bi bi-box-arrow-up-right"></i>
            </span>
          </a>
        </div>
      )
    }];

    return (
      <RequestsContent />
    )

    return (
      <div className="settings organization-users container-fluid px-5" ref={ref => this.el = ref}>
        <div className="content-header">
          <div className="title">Requests</div>
          <div className="subheader">
            <div className="page">Follow-up items</div>
          </div>
        </div>
        <div className="content-container">
          <div className="summary">
            <div className="summary-actions">
              <div className="count">
                <div>Total assignments</div>
                <div className="count-container">{assignmentsCount}</div>
              </div>
            </div>
          </div>
          <div className="container-fluid table-details-section">
            <ReactTable
              data={assignments}
              defaultPageSize={DEFAULT_PAGE_SIZE}
              loading={this.state.loading}
              pages={pages}
              columns={columns}
              manual
              onFetchData={(state, instance) => {
                this.setState({ loading: true });
                this.handleGetPaginatedAssignments(state.page, state.pageSize);
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

Content.propTypes = {
};

export default Content;

const handleCurrencySymbol = currency =>
  currency ? currencyCodesHash[currency] : '';

const getWorkspaceName = ({ workspaceId, workspaces }) => {
  const foundWorkspace =
    workspaces.filter(workspace => workspace.id === workspaceId);

  return foundWorkspace?.length ? foundWorkspace[0].name : 'Unknown';
};
