import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import Card from 'react-bootstrap/Card'
import Spinner from 'react-bootstrap/Spinner';

import MaterialUiTooltip from './MaterialUiTooltip';
import NoDataAvailableWidgetState from './NoDataAvailableWidgetState';

import {
  ellipsis,
  numToLocaleString,
  statusBadge,
  statusMapping,
} from '../../../components/util/helpers';
import { fetchWorkspaceTeamRequestsNearRenewal } from '../../../services/stats_api';
import { requestsForMyTeamByRenewalDemoData } from '../util/demo_data';

class RequestsForMyTeamByRenewal extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isError: false,
      renewalItems: [],
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    const { currentUser, useDemoData, workspaceId } = this.props;
    const organizationId = currentUser?.organization_id;

    const response = useDemoData
      ? requestsForMyTeamByRenewalDemoData({ persona: 'tech' })
      : await fetchWorkspaceTeamRequestsNearRenewal({
          workspaceId,
          organizationId,
        });

    if (this._isMounted) {
      if (!(response || {}).error) {
        this.setState({ isLoading: false, renewalItems: response.entries })
      } else {
        this.setState({ isLoading: false, isError: true });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {
      currentLocale,
      currentUser,
      defaultChannelId,
      handleGeneralUpgradePlanModal,
      useDemoData,
      workspaceId,
    } = this.props;

    const WIDGET_TITLE =
      `Time Sensitive: Customer Requests For My Team(s) as Defined by Renewal Dates`;

    const { isLoading, renewalItems } = this.state;

    const data = renewalItems;
    const isEmptyState = renewalItems && !renewalItems.length;

    if (isLoading) {
      return (
        <Card className="unresolved-requests-near-renewal-card">
          <Card.Header>
            <div className="title">{WIDGET_TITLE}</div>
            <MaterialUiTooltip titleContent={titleContent()} />
          </Card.Header>
          <Card.Body className="card-content align-items-center">
            <Card.Text className="text-center" as="div">
              <Spinner animation="border" role="status" variant="secondary" />
            </Card.Text>
          </Card.Body>
        </Card>
      );
    }

    if (!isLoading && isEmptyState) {
      return (
        <Card className="unresolved-requests-near-renewal-card">
          <Card.Header>
            <div className="title">{WIDGET_TITLE}</div>
            <MaterialUiTooltip titleContent={titleContent()} />
          </Card.Header>
          <Card.Body className="card-content align-items-center">
            <Card.Text className="text-center empty-state-container" as="div">
              <NoDataAvailableWidgetState
                message="No active requests for my team(s) from customers approaching renewal"
                currentLocale={currentLocale}
              />
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <Card.Text
              className="dashboard-create-channel-action"
              onClick={() => handleGeneralUpgradePlanModal({})}
            >
              Create a Request
            </Card.Text>
          </Card.Footer>
        </Card>
      );
    }

    if (!isLoading && !isEmptyState) {
      return (
        <Card className="unresolved-requests-near-renewal-card">
          <Card.Header>
            <div className="title">{WIDGET_TITLE}</div>
            <MaterialUiTooltip titleContent={titleContent()} />
          </Card.Header>
          <Card.Body className="card-content">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 500 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      className="column-header pt-4"
                    >
                      Customer(s) Helped
                    </TableCell>
                    <TableCell
                      className="column-header pt-4"
                    >
                      Status
                    </TableCell>
                    <TableCell className="column-header pt-4">
                      Renewal Quarter
                    </TableCell>
                    <TableCell className="column-header pt-4">
                      Renewal date
                    </TableCell>
                    <TableCell className="column-header pt-4">
                      Request
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.map((row) => (
                    <TableRow
                      key={row.channel_id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell className="dashboard-table-cell ps-3 py-3">
                        {row.renewals_approaching.map(tag => (
                          <div key={tag.name}>{tag.name}</div>
                        ))}
                      </TableCell>
                      <TableCell className="dashboard-table-cell request-status ps-3 py-3">
                        <div className={`status pill badge ${statusBadge(row.status)} d-flex justify-content-center align-items-center`}>
                          {statusMapping(row.status)}
                        </div>
                      </TableCell>
                      <TableCell className="dashboard-table-cell request-age ps-3 py-3">
                        {getQuarter(row.earliest_renewal_date)}
                      </TableCell>
                      <TableCell className="dashboard-table-cell request-age ps-3 py-3">
                        {row.renewals_approaching?.length > 2 && row.renewals_approaching.slice(0,2).map(tag => (
                          <div key={tag.name}>
                            <div>{tag.customer_renewal_date}</div>
                          </div>
                        ))}
                        {row.renewals_approaching?.length > 2 && (
                          <div>+{row.renewals_approaching.length - 2}</div>
                        )}
                        {row.renewals_approaching?.length < 3 &&
                          row.renewals_approaching.map(tag => (
                            <div key={tag.name}>
                              <div>{tag.customer_renewal_date}</div>
                            </div>
                          ))}
                      </TableCell>
                      <TableCell scope="row" className="dashboard-table-cell channel-path ps-3 py-3">
                        {useDemoData ? (
                          <abbr title={row.channel_name}>
                            {
                              row.channel_name &&
                              `${ellipsis({ string: row.channel_name, maxLength: 40 })}`
                            }
                          </abbr>
                        ) : (
                          <a
                            href={`/#/${currentLocale}/workspaces/${workspaceId}/requests/${row.channel_id}`}
                            target="_blank"
                          >
                            <abbr title={row.channel_name}>
                              {
                                row.channel_name &&
                                `${ellipsis({ string: row.channel_name, maxLength: 40 })}`
                              }
                            </abbr>
                          </a>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card.Body>
          <Card.Footer>
            <Card.Text
              className="dashboard-create-channel-action"
              onClick={() => handleGeneralUpgradePlanModal({})}
            >
              Create a Request
            </Card.Text>
          </Card.Footer>
        </Card>
      );
    }
  }
}

RequestsForMyTeamByRenewal.propTypes = {};

export default RequestsForMyTeamByRenewal;

const getQuarter = date => {
  if (!date) { return 'N/A'; }
  const newDate = moment(date);
  const quarter = newDate.quarter();
  const year = newDate.year();
  return `Q${quarter} ${year}`;
};

const titleContent = () => (
  <div className="tooltip-content">
    <p className="tooltip-paragraph header">
      Requests directed to my team(s) that can impact renewals:
    </p>
    <p className="tooltip-paragraph">
      Sales and Customer Success team members can manage customers in{' '}
      <b>settings</b> and input <b>renewal dates</b> for customers.
    </p>
    <p className="tooltip-paragraph">
      By using the <b>customers</b> field when managing a request, you help
      product and delivery teams identify which requests can impact renewals.
    </p>
    <p className="tooltip-paragraph">
      By using the <b>entrusted teams</b> field when managing a request, you can
      alert relevant team(s) about a request related to their product area.
    </p>
    <p className="tooltip-paragraph">
      This enables delivery teams to have plenty of advanced noticed in order
      to properly align their backlog. <b>Happier customers and less stress...</b>
    </p>
    <p className="tooltip-paragraph">
      Engineers would prefer to learn of renewals before the final hour 😉.
    </p>
  </div>
);
